<template>
  <div class="reportList">
    <ReportListComponent v-if="configId != null" :reportConfigId="configId" :reportConfigName="configName"/>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ReportListComponent from '@/components/ReportListComponent.vue';

export default {
  name: 'ReportListView',
  props: ['configId', 'configName'],
  components: {
    ReportListComponent,

  },
  data() {
    return {
    };
  },
  mounted() {
    // this.configName = this.$route.params.configName;
    // this.configId = this.$route.params.configId;
    // console.log(this.configName, this.configId);
  },
};
</script>
